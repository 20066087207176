.purchase_button_containerNis {
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    display: flex;
    position: fixed;
    align-items: center;
    /*padding: 0.3rem 1.5rem;*/
    background-color: #f2effb;
    justify-content: center;
    box-shadow: 0rem -0.3rem 0.5rem rgba(0, 0, 0, 0.2);
}

.left_sectionNis > h2 > span {
    color: #c2bdd3;
    font-size: 1.2rem;
    font-weight: 500;
    text-decoration: line-through;
}
.contentLeft{
    margin-right: 15px;
    font-size: 1.8rem;
    font-weight: 500;
    margin-top:10px;
}

.left_sectionNis > h2 {
    margin: 0;
    font-size: 1.4rem;
    font-weight: 500;
}

.left_sectionNis > p {
    color: #574d75;
    font-weight: 600;
    font-size: 0.9rem;
}

.left_sectionNis > p > span {
    color: #6941dd;
}



.left_sectionNis,
.right_sectionNis {
    /*width: 5=40%;*/
}

.right_sectionNis > a {
    color: #fff;
    font-size: 1.8rem;
    font-weight: 400;
    cursor: pointer;
    margin: 10px;
    width: fit-content;
    padding: 1rem 2rem;
    border-radius: 0.5rem;
    background: linear-gradient(135deg, #5330BA 0%, #8E2596 100%);
    text-decoration: none;
}
.left_sectionNis p {
    font-weight: 700;
}

@media (max-width: 768px) {
    .purchase_button_containerNis {
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 100;
        display: flex;
        position: fixed;
        align-items: center;
        /*padding: 0.3rem 1.5rem;*/
        background-color: #f2effb;
        justify-content: center;
        padding-top: 10px;
        padding-bottom: 10px;
        box-shadow: 0rem -0.3rem 0.5rem rgba(0, 0, 0, 0.2);
    }
    .contentLeft{
        width:50%;
        margin-left: 10px;
        font-size: 1rem;
        font-weight: 500;
    }
    .right_sectionNis > a {
        color: #fff;
        font-size: 10px;
        font-weight: 400;
        cursor: pointer;
        margin: 10px;
        width: fit-content;
        padding: 1rem 2rem;
        border-radius: 0.5rem;
        background: linear-gradient(135deg, #5330BA 0%, #8E2596 100%);
    }
}