.footerContainer {
    width: 100%;
    margin: 0 auto;
    padding: 2rem 0rem 1rem 0rem;
    background-color: #f2effb;
}
.StrideAhead > img {
    width: 10vh;
}

.footerContainer > hr {
    border: 0;
    padding: 0.05rem;
    margin-bottom: 1.5rem;
    background-color: #d6e8e6;
}

.StrideAhead {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Intro {
    width: 100%;
    display: flex;
    margin: 1.5rem 0 0 0;
    text-align: center;
    flex-direction: column;
}

.light {
    color: #938caa;
    font-size: 0.8rem;
    margin-bottom: 1rem;
    line-height: 1.2rem;
}

.purple {
    width: 61%;
    color: #8667df;
    font-size: 0.8rem;
    margin-bottom: 1rem;
    line-height: 1.2rem;
}

.ending {
    display: flex;
    text-align: center;
    font-size: 0.8rem;
}

.ending > p {
    color: #a2afb7;
}

.GrLocation {
    color: #8667df;
    font-size: 1.2rem;
}

.row {
    width: 100%;
    display: flex;
    justify-content: center;
}

.ending {
    width: 99%;
    display: flex;
    margin: 0 auto;
    font-weight: 300;
    line-height: 1rem;
    font-size: 0.6rem;
    flex-direction: column;
}
.desktopCopyRights {
    display: none;
}

@media screen and (min-width: 768px) {
    .StrideAhead > img {
        margin-top: 1rem;
    }
    .footerContainer {
        width: 100%;
        display: flex;
        margin: 0 auto;
        flex-direction: row;
        padding: 2rem 17.8%;
        background-color: #f2effb;
    }
    .Intro {
        flex-direction: row;
    }
    .purple {
        margin: 0;
    }
    .GrLocation {
        margin: 0;
    }
    .ending {
        display: none;
    }
    .desktopCopyRights {
        display: block;
    }

    .footerContainer > hr {
        display: none;
    }

    .desktopCopyRights {
        padding: 2rem 0;
        background-color: #f2effb;
    }
    .desktopCopyRights > hr {
        border: 0;
        padding: 0.03rem;
        margin: 0 0 1rem 0;
        background-color: #9d83e4;
    }
    .desktopCopyRights > p {
        width: 100%;
        color: #a2afb7;
        font-size: 0.8rem;
        text-align: center;
    }
    .termsContainer {
        width: 90%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}


.footerDesign {
    padding: 50px 0 16px 0;
    background: #FFF0EE;
}

.container-md {
    max-width: calc(1440px - 40px);
    padding: 0 20px;
    margin: 0 auto;
}

.footerContentAlignment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 50px 0;
    /* border-bottom: 1px solid rgb(255 0 0);; */
    /* rgb(157 ,131 ,228 , .6); */
}
.footerCopyrightContent {
    padding: 15px 0 0 0;
    border-top: 1px solid rgb(157 ,131 ,228 , .6);
}

.footerLogo img {
    max-width: 136px;
}
.footerCopyrightContent p {
    font-weight: 400;
    cursor: pointer;
    font-size: 14px;
    letter-spacing: -0.01em;
    text-align: center;
    color: #281E42;
    opacity: 0.5;
    line-height: 18px;
}

.locationContentAlignment {
    display: flex;
}
.locationContentAlignment div + div {
    padding-left: 12px;
}
.locationContentAlignment p {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #352958;
    opacity: .5;
    max-width: 258px;
}

.grayLocationAlignment {
    display: flex;
}

.grayLocationAlignment div + div {
    padding-left: 12px;
}

.grayLocationAlignment div p {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    max-width: 191px;
    letter-spacing: 0.01em;
    color: #352958;
    opacity: .5;
}

.footerLinkTextAlignment {
    display: flex;
    align-items: center;
}

.footerLinkTextAlignment a {
    font-weight: 400;
    font-size: 20px;
    line-height: 35px;
    display: block;
    cursor: pointer;
    padding-right: 40px;
    letter-spacing: 0.01em;
    color: #352958;
    opacity: .5;
}

.footerLinkTextAlignment a:last-child {
    padding: 0;
}

@media screen and (max-width: 1500px) {
    .container-md {
        max-width: 100%;
        padding: 0 80px;
    }

    .locationContentAlignment {
        padding: 40px 0;
    }
    .footerLinkTextAlignment {
        justify-content: center;
    }
    .footerLinkTextAlignment a {
        font-size: 12px;
        line-height: 16px;
        padding-right: 15px;
    }
}

@media screen and (max-width: 992px)  {
    .footerContentAlignment {
        display: block;
    }
}
