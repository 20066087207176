.label_for_pie_chart_container {
    padding-top: 4rem;
}

.rect_color_title_container {
    display: flex;
    justify-content: flex-start;
    gap: 10px;

    align-items: center;
}

.rectangle_color_box {
    width: 60px;
    height: 20px;


}

.rect_color_title_container p {
    font-weight: 600 !important;
    margin: 0 !important;
    font-size: 16px !important;
    text-transform: capitalize;
    color: #000 !important;
    letter-spacing: 0.02em !important;
    line-height: 42px !important;
}