

 .detailContainer {
   width: 80%;
   max-width: 650px;
   min-height: 50vh;
   border-radius: 0.5rem;
   background-color: #ffff;
   padding: 40px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   border-radius: 30px;
 }
 .payment-image{
    width: 170px;
    height: 170px;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
 }
 .payment-text{
  color: rgba(53, 41, 88, 1);
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  font-style: Poppins;
  margin-top: 10px;
 }
 .payment-description{
  width: 100%;
  text-align: center;
  color: rgba(53, 41, 88, 1);
  font-size: 16px;
  font-weight: Medium;
 }
 .share-image {
  width: 9%;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 100px;
  background-color: #ffff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  cursor: pointer;
 }
 .payment-box-footer{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  justify-items: center;
  text-align: center;
  margin-top: 12px;
 }
 .share-image img{
  width: 30px;
  height: 30px; 
  flex: none;
  order: 1;
  flex-grow: 0;
 }
 .add-calender{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 15px;
  width: 233px;
  height: 50px;
  background: #8667DE;
  border-radius: 10px;
  cursor: pointer;
 }
 .calender-logo{
  width: 24px;
  height: 24px;
 }
 .add-calender-text{
  width: 146px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 0px;
  color: #FFFFFF;
 }




 @media screen and (max-width: 650px) {

  .payment-image{
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
 }
 .payment-text {
  color: rgba(53, 41, 88, 1);
  font-size: 21px;
  font-weight: bold;
  text-align: center;
  font-style: Poppins;
  margin-top: 10px;
}
.payment-box-footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  justify-items: center;
  text-align: center;
  margin-top: 12px;
}
.add-calender {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 15px;
  grid-gap: 15px;
  gap: 15px;
  width: 160px;
  height: 31px;
  background: #8667DE;
  border-radius: 10px;
}
.calender-logo {
  width: 24px;
  height: 15px;
}
.add-calender-text {
  width: 143px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 26px;
  margin-bottom: 0px;
  color: #FFFFFF;
}
.share-image {
  width: 40px;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0px;
  margin-right: 10px;
  background-color: #ffff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.payment-description {
  width: 100%;
  text-align: center;
  color: rgba(53, 41, 88, 1);
  font-size: 13px;
  font-weight: Medium;
}
 }
 @media screen and (max-width: 350px) {

  .payment-image{
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
 }
 .payment-text {
  color: rgba(53, 41, 88, 1);
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  font-style: Poppins;
  margin-top: 10px;
}
.payment-box-footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  justify-items: center;
  text-align: center;
  margin-top: 12px;
}
.add-calender {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 8px;
  grid-gap: 15px;
  gap: 15px;
  width: 143px;
  height: 35px;
  background: #8667DE;
  border-radius: 10px;
}
.calender-logo {
  width: 19px;
  height: 17px;
}
.add-calender-text {
  width: 143px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 26px;
  margin-bottom: 0px;
  color: #FFFFFF;
}
.share-image {
  width: 32px;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0px;
  background-color: #ffff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.payment-description {
  width: 100%;
  text-align: center;
  color: rgba(53, 41, 88, 1);
  font-size: 11px;
  font-weight: Medium;
}
 }