:root {
    --color-low: #EE3323;
    --color-mid: #FCAE17;
    --color-high: #24A84A;
}

.main-container {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins';
}

.main-container {
    max-width: 1160px;
    justify-content: center;
    margin: 0 auto;
    /*background-color: #fff;*/
}

.strideLogo {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    height: 70px;
}

.assessment_details {
    display: flex;
    flex-direction: row;
    /*margin-left: 140px;*/
    /*margin-right: 140px;*/
    margin-top: 40px;
    justify-content: space-around;
    margin-bottom: 40px;
}

.left-container {
    display: flex;
    text-align: center;
    /* background-color: rgb(223, 223, 223); */
    flex-grow: 1.5;
    align-items: flex-start;
    flex-direction: column;
}

h2 {
    font-size: 30px;
    letter-spacing: 0.1;
    color: #192A46;
    font-weight: 600;
    margin-bottom: 10px;
}

h3 {
    font-size: 26px;
    margin-top: 10px;
    color: #192A46;
    font-weight: 600;
    margin-bottom: 5px;
}

h4 {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
}

h5 {
    margin-left: 20px;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
    color: #192A46;
}

h6 {
    font-size: 16px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #192A46;
}

ul {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #192A46;
    margin-left: 20px;
    margin-right: 10px;
    margin-bottom: 10px;
}

#improvement {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 36px;
    color: #192A46;
}

#improveList {
    font-size: 18px;
    line-height: 27px;
    color: #192A46;
}

#line {
    width: 50px;
    height: 0px;
    border-color: 2px solid #47556B;
}

#line2 {
    width: auto;
    height: 0px;
    /*margin-left: 140px;*/
    /*margin-right: 140px;*/
    border-color: 2px solid #47556B;
}

.assessment_details-extend {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.ade-text {
    font-size: 18px;
    color: #47556B;
    font-weight: 500;

}

.datas {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    /*line-height: 30px;*/
    color: #47556B;
    margin-left: 5px;
    font-size: 18px;

}

.right-container {
    display: flex;
    justify-content: flex-end;
    text-align: center;
    /*background-color: orange;*/
    flex-grow: 2;
    color: white;
    font-size: 20px;
    flex-grow: none;
    transform: scale(0.8);
    margin-right: 0px
}

.section2, .section3, .section4, .section5, .section6, .section7, .copyright {
    /*margin-left: 140px;*/
    /*margin-right: 140px;*/
    margin-top: 30px;
    margin-bottom: 30px;
}

.section2{
    margin-top: 20px;
    margin-bottom: 20px;
}

.section2 h2{
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
}

.section2 p{
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
}

.section3 {
    height: auto;
}

.section3 h2{
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
}

.section3 p{
    font-weight: 400;
    font-size: 16px;
    line-height: 36px;
}
.section4 h2{
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
}

.section4 p{
    font-weight: 400;
    font-size: 16px;
    line-height: 36px;
    margin-bottom: 0px;
}
.section5 h2{
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 5px;
}

.section5 p{
    font-weight: 400;
    font-size: 16px;
    line-height: 36px;
}
.section6 h2{
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
}

.section6 p{
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
}
.section7 h2{
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
}
.section7 h3{
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
}
#improveList {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
}

.section7 p{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.definition, .definitionSmall {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #717883;
}

.definitionSmall {
    font-size: 16px;
    margin-bottom: 30px;
}

.indicators {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.block1, .block2, .block3 {
    display: flex;
    height: 70px;
    flex-direction: row;
    color: #192A46;
    font-size: 15px;
    flex-grow: 1;
    border-radius: 5px;
    margin: 30px 0px 0px;
    background: rgba(235, 240, 248, 0.1);
    border: 2.6px solid #EBF0F8;
    justify-content: center;
    align-items: center;
}

.block1 h4 {
    margin-bottom: 0px;
}

.block2 h4 {
    margin-bottom: 0px;
}

.block3 h4 {
    margin-bottom: 0px;
}

.dot {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border-radius: 50%;
    display: inline-block;
    margin-right: 15px;
    margin-bottom: 0px;
}

#lowDot {
    background-color: #EE3323;;
}

#midDot {
    background-color: #FCAE17;
}

#highDot {
    background-color: #24A84A;
}

.containers {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 101%;
}

.right_container, .left_container {
    flex-grow: 2;
    border: 2px solid #EBF0F8;
    border-radius: 15px;
    margin: 10px 0px;
}

.right_container ul {
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 16px;
}

.heading {
    display: flex;
    flex-direction: row;
    height: auto;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 16px;
}

.right_portion {
    display: flex;
    flex-direction: row-reverse;
    flex-grow: 1;
    flex-grow: none;
    align-items: flex-start;
}

.left_portion {
    display: flex;
    flex-direction: row;
    /*background-color: #fff;*/
    flex-grow: 1;
    flex-grow: none;
    align-items: flex-start;
}

.definition2 {
    /*width: 525px;*/
    height: auto;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    color: #717883;
    margin: 16px;
}

.icon_image {
    display: flex;
    width: 60px;
    height: 60px;
    background-color: #E8EFFB;
    border-radius: 15px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
}
.icon_image_accordion {
    display: flex;
    width: 82px;
    height: 82px;
    background-color: #E8EFFB;
    border-radius: 15px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
}

#trait {
    margin-left: 15px;
    flex-direction: column;
}
#trait h4{
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 0px;
}

#capsule {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    background: rgba(36, 168, 74, 0.05);
    border: 1.4px solid #24A84A;
    border-radius: 100px;
    padding: 5px 24px;
    gap: 5px;
    color: #24A84A;
}

.circle_ {
    display: flex;
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.circle_accordion {
    display: flex;
    width: 80px;
    height: 8px;
    border-radius: 50%;
}

.circle-container {
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    text-align: center;
    color: #192A46;
}

.accordion-closed {
    display: flex;
    align-items: center;
    height: 120px;
    background: #FFFFFF;
    border: 2px solid rgba(113, 120, 131, 0.3);
    border-radius: 20px;
    margin-bottom: 30px;
}

.accordion-open {
    height: 278px;
    background: #FFFFFF;
    border: 2px solid rgba(113, 120, 131, 0.3);
    border-radius: 20px;
    margin-bottom: 30px;
}

.accordion-show {
    height: auto;
    background: #FFFFFF;
    border: 2px solid rgba(113, 120, 131, 0.3);
    border-radius: 20px;
    margin-bottom: 30px;
}

.accordion-hide {
    display: none;
}

#accordion-p {
    /*width: 900px;*/
    height: auto;
    /*margin-left: 25px;*/
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    color: #717883;
}

#accordion-capsule {
    display: flex;
    transform: scale(0.7);
}

.left-part {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    align-items: center;

}

.left-inside {
    display: flex;
    margin-left: 0px;
    flex-direction: column;
}

.right-part {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    /*align-items: center;*/
    justify-content: flex-end;
}

.right-part2 {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.inlineBlock {
    display: flex;
    /*margin: 25px 25px;*/
    padding: 30px;
    flex-grow: 1;
    border-radius: 10px;
}
.inlineBlock2 {
    display: flex;
    /*margin: 25px 25px;*/
    padding: 30px 30px 20px;
    flex-grow: 1;
    border-radius: 10px;
}

.accordion-content {
    padding: 0px 30px 30px;
}

.accordion-content h5 {
    margin: 0px;
    font-size: 16px;
    margin-bottom: 10px;
}

.accordion-content ul {
    margin: 0px;
    font-size: 16px;
}


#accordion-title {
    margin-left: 15px;
    margin-bottom: 0px;
    margin-top: 0px;
    font-weight: 600;
    font-size: 22px;
}

.capsule-container {
    margin-left: 40px;
    width: 136px;
    height: 43px;
    border-radius: 100px;
}

.bigCapsule {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 136px;
    height: 43px;
    border-radius: 100px;
    font-weight: 500;
    font-size: 18px;
}

.capsule-high {
    background: rgba(36, 168, 74, 0.05);
    border: 2px solid #24A84A;
    color: #24A84A;
}

.capsule-mid {
    background: rgba(252, 174, 23, 0.05);
    border: 2px solid #FCAE17;
    color: #FCAE17;
}

.capsule-low {
    background: rgba(238, 51, 35, 0.05);
    border: 2px solid #EE3323;
    color: #EE3323;
}

#accordion-score {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #717883;
}

#accordion-percentage {
    color: #192A46;
    font-size: 30px;
    font-weight: 600;
}

.bannerImage {
    display: flex;
    height: 300px;
    left: 140px;
    top: 3345px;
    margin-top: 40px;
    margin-bottom: 40px;
    background: #1A64D6;
    border-radius: 20px;
}

.bannerLeft, .bannerRight {
    flex-grow: 1;
    margin: 0px;
}

.bannerLeft {
    margin-left: 50px;
    margin-top: 50px;
}

.bannerRight {
    display: flex;
    margin-top: 50px;
    margin-right: 50px;
    flex-direction: row-reverse;
    align-items: flex-start;
}

#bannerTxt {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 45px;
    color: white;
}

#bannerPara {
    width: 510px;
    height: 48px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 40px;

}

#bannerBtn {
    width: auto;
    height: auto;
    padding: 10px 30px;
    cursor: pointer;
    outline: none;
    border: none;
    border-radius: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #1A64D6;
}

.definition3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #192A46;
}

#lastPara {
    margin-bottom: 30px;
}

.copyright {
    display: flex;
    flex-direction: row;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(25, 42, 70, 0.8);
    margin-bottom: 20px;
}

.radar-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0px;
    margin-top: 0px;
}

.radar-desc {
    margin-top: 0px;
    display: flex;
    height: 50px;
    flex-direction: row-reverse;
    align-items: flex-end;
    border-radius: 10px;
}

.radarNotation {
    font-weight: 500;
    font-size: 14px;
    font-family: 'Poppins';
    font-style: normal;
    line-height: 24px;
    color: rgba(25, 42, 70, 0.8);
    margin-left: 10px;
}

.hex {
    margin-top: 0px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.average, .your-performance, .top-candidates {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 30px;
}

#avg {
    background: rgba(26, 100, 214, 0.1);
    border: 2px solid #1A64D6;

}

#topp {
    background: rgba(47, 150, 101, 0.1);
    border: 2px solid #2F9665;
}

#yourp {
    background: rgba(227, 58, 47, 0.1);
    border: 2px solid #E33A2F;
}


/*.gauge {*/
/*    width: 100%;*/
/*    max-width: 250px;*/
/*    font-family: "Roboto", sans-serif;*/
/*    font-size: 32px;*/
/*    color: #004033;*/
/*}*/

/*.gauge__body {*/
/*    width: 100%;*/
/*    height: 0;*/
/*    padding-bottom: 50%;*/
/*    background: #b4c0be;*/
/*    position: relative;*/
/*    border-top-left-radius: 100% 200%;*/
/*    border-top-right-radius: 100% 200%;*/
/*    overflow: hidden;*/
/*}*/

/*.gauge__fill {*/
/*    position: absolute;*/
/*    top: 100%;*/
/*    left: 0;*/
/*    width: inherit;*/
/*    height: 100%;*/
/*    background: #009578;*/
/*    transform-origin: center top;*/
/*    transform: rotate(0.25turn);*/
/*    transition: transform 0.2s ease-out;*/
/*}*/

/*.gauge__cover {*/
/*    width: 75%;*/
/*    height: 150%;*/
/*    background: #ffffff;*/
/*    border-radius: 50%;*/
/*    position: absolute;*/
/*    top: 25%;*/
/*    left: 50%;*/
/*    transform: translateX(-50%);*/

/*    !* Text *!*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    padding-bottom: 25%;*/
/*    box-sizing: border-box;*/
/*}*/

@media (max-width: 768px) {

    .MainContainer1 {
        padding: 0px !important;
    }

    .main-container {
        padding: 20px;
    }

    .strideLogo {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        width: 100%;
    }

    .strideLogo img {
        width: 30%;
    }

    .assessment_details {
        display: flex;
        flex-direction: column;
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 40px;
        justify-content: space-around;
        margin-bottom: 40px;
    }

    .assessment_details h2 {
        font-weight: 600;
        font-size: 18px;
        color: #192A46;
    }

    .assessment_details h3 {
        font-weight: 600;
        font-size: 16px;
        color: #192A46;
    }

    .assessment_details h4 {
        font-weight: 500;
        font-size: 12px;
        line-height: 24px;
        color: #47556B;
    }

    .assessment_details span {
        font-weight: 600;
        font-size: 12px;
        line-height: 24px;
        color: #47556B;
    }

    .right-container {
        display: flex;
        justify-content: center;
        text-align: center;
        /*background-color: orange;*/
        flex-grow: 2;
        color: white;
        font-size: 20px;
        flex-grow: none;
        transform: scale(0.8);
        width: 100%;
        margin-right: 0px
    }

    #line2 {
        width: auto;
        height: 0px;
        margin-left: 0px;
        margin-right: 0px;
        border-color: 2px solid #47556B;
    }

    .section2, .section3, .section4, .section5, .section6, .section7, .copyright {
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .section2 h2 {
        font-weight: 600;
        font-size: 18px;
    }

    .section2 p {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #717883;
    }

    .section3 {
        height: auto;
    }

    .section3 h2 {
        font-weight: 600;
        font-size: 18px;
    }

    .section3 p {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #717883;
    }

    .radar-container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 0px;
        margin-top: 0px;
        width: 100%;
    }

    .radar-desc {
        display: flex;
        height: 50px;
        flex-direction: row;
        align-items: center;
        border-radius: 10px;
        width: 100%;
    }

    .radar-desc span {
        font-weight: 500;
        font-size: 10px;
        line-height: 22px;
    }

    .your-performance {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-left: 0px;
    }

    .average {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-left: 0px;
    }

    .top-candidates {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-left: 12px;
        margin-right: 12px;
    }

    .radarNotation {
        margin-left: 5px;
    }

    .hex {
        margin-top: 0px;
    }


    .section4 h2 {
        font-weight: 600;
        font-size: 18px;
    }

    .section4 p {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #717883;
    }

    .indicators {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .block1, .block2, .block3 {
        height: 51px;
        width: 100%;
        display: flex;
        flex-direction: row;
        color: #192A46;
        font-size: 15px;
        flex-grow: 1;
        margin: 20px 0px !important;
        background: rgba(235, 240, 248, 0.1);
        border: 2.6px solid #EBF0F8;
        border-radius: 10px;
        justify-content: start;
        align-items: center;
        padding: 15px;

    }

    .block2 {
        width: 100%;
        display: flex;
        flex-direction: row;
        color: #192A46;
        font-size: 15px;
        flex-grow: 1;
        margin: 10px 0px !important;
        background: rgba(235, 240, 248, 0.1);
        border: 2.6px solid #EBF0F8;
        border-radius: 10px;
        justify-content: start;
        align-items: center;
        padding: 15px;
    }

    .block1 h4 {
        font-size: 14px;
        margin-bottom: 0px;
    }

    .block2 h4 {
        font-size: 14px;
        margin-bottom: 0px;
    }

    .block3 h4 {
        font-size: 14px;
        margin-bottom: 0px;
    }

    .dot {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        /*border-radius: 50%;*/
        display: inline-block;
        margin-right: 15px;
        margin-bottom: 0px;
    }

    .section5 h2 {
        font-weight: 600;
        font-size: 18px;
    }

    .section5 p {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #717883;
    }

    .containers {
        display: flex;
        flex-direction: column;
        width: 104%;
    }

    .right_container, .left_container {
        flex-grow: 2;
        height: auto;
        border: 2px solid #EBF0F8;
        border-radius: 15px;
        margin: 0px;
        margin-bottom: 20px;
        padding: 16px;
    }

    .right_container h5 {
        margin-top: 10px;
        margin-left: 0px;
    }

    .right_container ul {
        margin: 0px;
        font-weight: 400;
        font-size: 10px;
        line-height: 20px;
    }

    .left_container h5 {
        margin-top: 10px;
        margin-left: 0px;
    }

    .left_container ul {
        margin: 0px;
        font-weight: 400;
        font-size: 10px;
        line-height: 20px;
    }

    .heading {
        display: flex;
        flex-direction: row;
        height: 70px;
        font-weight: 600;
        font-size: 14px;
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 0px;
    }

    #trait {
        margin-left: 10px;
    }

    #trait h4 {
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        margin-bottom: 0px;
    }

    .icon_image {
        display: flex;
        width: 50px;
        height: 50px;
        background-color: #E8EFFB;
        border-radius: 10px;
        justify-content: center;
        align-items: center;
    }

    .circle_ {
        display: flex;
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }

    .circle_accordion {
        display: flex;
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }

    .circle-container {
        font-weight: 600;
        font-size: 22px;
        line-height: 33px;
        text-align: center;
        color: #192A46;
    }

    .accordion-open {
        height: 278px;
        background: #FFFFFF;
        border: 2px solid rgba(113, 120, 131, 0.3);
        border-radius: 20px;
        margin-bottom: 30px;
    }

    .accordion-show {
        height: auto;
        background: #FFFFFF;
        border: 2px solid rgba(113, 120, 131, 0.3);
        border-radius: 20px;
        margin-bottom: 30px;

    }
    .icon_image_accordion {
        display: flex;
        width: 50px;
        height: 50px;
        background-color: #E8EFFB;
        border-radius: 15px;
        border-radius: 10px;
        justify-content: center;
        align-items: center;
    }

    .accordion-show ul {
        /*margin-left: 10px;*/
        margin: 0px;
        font-weight: 400;
        font-size: 10px;
        line-height: 20px;
    }

    .accordion-show h5 {
        margin: 0px;
    }

    .accordion-hide {
        display: none;
    }

    .accordion-content {
        padding: 0px 16px 16px 16px;
    }

    #accordion-p {
        width: 100%;
        height: auto;
        margin-left: 0px;
        padding: 0px;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #717883;
    }

    #accordion-capsule {
        display: flex;
        transform: scale(0.7);
    }

    .left-part {
        display: flex;
        flex-grow: 1;
        flex-direction: row;
        align-items: center;

    }

    .left-inside {
        display: flex;
        margin-left: 5px;
        flex-direction: column;
        justify-content: flex-start;
    }

    .definition2 {
        width: 100%;
        height: auto;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #717883;
        margin: 0px;
    }

    .section6 h2 {
        font-weight: 600;
        font-size: 18px;
    }

    .section6 p {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #192A46;
    }

    .accordion-closed {
        display: flex;
        align-items: center;
        height: auto;
        background: #FFFFFF;
        border: 2px solid rgba(113, 120, 131, 0.3);
        border-radius: 20px;
        margin-bottom: 30px;
    }

    .right-part {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        align-items: end;
        justify-content: flex-start;
    }
    .right-part2 {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        align-items: end;
        justify-content: flex-end;
    }
    .right-part2 h3{
        margin: 0px;
    }

    .inlineBlock {
        display: flex;
        margin: 0px;
        flex-grow: 1;
        border-radius: 10px;
        padding: 16px;
        height: 90px;
    }
    .inlineBlock2 {
        display: flex;
        margin: 0px;
        flex-grow: 1;
        border-radius: 10px;
        padding: 16px;
        height: 90px;
    }

    #accordion-title {
        font-weight: 600;
        font-size: 14px;
        margin-left: 10px;
    }

    .capsule-container {
        margin-left: 0px;
        width: 80px;
        height: 40px;
        border-radius: 100px;
    }

    .bigCapsule {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 30px;
        border-radius: 100px;
        font-weight: 500;
        font-size: 12px;
    }

    .capsule-high {
        background: rgba(36, 168, 74, 0.05);
        border: 2px solid #24A84A;
        color: #24A84A;
    }

    .capsule-mid {
        background: rgba(252, 174, 23, 0.05);
        border: 2px solid #FCAE17;
        color: #FCAE17;
    }

    .capsule-low {
        background: rgba(238, 51, 35, 0.05);
        border: 2px solid #EE3323;
        color: #EE3323;
    }

    #accordion-score {
        font-weight: 600;
        font-size: 10px;
        /*line-height: 30px;*/
        color: #717883;
    }

    #accordion-percentage {
        color: #192A46;
        font-weight: 600;
        font-size: 10px;
    }

    .section7 h2 {
        font-weight: 600;
        font-size: 18px;
    }

    .section7 p {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #192A46;
    }

    #improveList {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #192A46;
    }

    #improvement {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 20px;
        color: #192A46;
        color: #192A46;
        margin: 0px;
    }

    .bannerImage {
        display: flex;
        flex-direction: column-reverse;
        height: auto;
        left: 140px;
        top: 3345px;
        margin-top: 40px;
        margin-bottom: 40px;
        background: #1A64D6;
        border-radius: 20px;
    }

    .bannerLeft, .bannerRight {
        flex-grow: 1;
        margin: 10px;
    }

    .bannerLeft {
        margin: 0px;
        padding: 20px;
        text-align: center;
    }

    .bannerRight {
        display: flex;
        margin: 0px;
        padding: 20px;
        flex-direction: row-reverse;
        align-items: flex-start;
    }

    .bannerRight img {
        width: 100%;
    }

    #bannerTxt {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        color: white;
    }

    #bannerPara {
        width: 100%;
        height: 48px;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: rgba(255, 255, 255, 0.5);
        margin-bottom: 47px;

    }

    #bannerBtn {
        padding: 10px 30px;
        cursor: pointer;
        outline: none;
        border: none;
        border-radius: 10px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: #1A64D6;
    }

    .definition3 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #192A46;
    }

    #lastPara {
        margin-bottom: 30px;
    }

    .copyright {
        display: flex;
        flex-direction: column;
        font-family: 'Poppins';
        align-items: center;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        color: #717883;
        color: rgba(25, 42, 70, 0.8);
        margin-bottom: 20px;
    }

}

@media (min-width: 769px) and (max-width: 992px) {

    .right-container {
        display: flex;
        justify-content: flex-end;
        margin-right: 0px;

    }

    #capsule {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        background: rgba(36, 168, 74, 0.05);
        border: 1.4px solid #24A84A;
        border-radius: 100px;
        padding: 5px 24px;
        gap: 5px;
        color: #24A84A;
    }

    .circle_ {
        display: flex;
        width: 70px;
        height: 70px;
        border-radius: 50%;
    }

    .circle_accordion {
        display: flex;
        width: 90px;
        height: 90px;
        border-radius: 50%;
    }

    .circle-container {
        font-weight: 600;
        font-size: 22px;
        line-height: 33px;
        text-align: center;
        color: #192A46;
    }

    .accordion-closed {
        display: flex;
        align-items: center;
        height: 120px;
        background: #FFFFFF;
        border: 2px solid rgba(113, 120, 131, 0.3);
        border-radius: 20px;
        margin-bottom: 30px;
    }

    .accordion-open {
        height: 278px;
        background: #FFFFFF;
        border: 2px solid rgba(113, 120, 131, 0.3);
        border-radius: 20px;
        margin-bottom: 30px;
    }

    .accordion-show {
        height: auto;
        background: #FFFFFF;
        border: 2px solid rgba(113, 120, 131, 0.3);
        border-radius: 20px;
        margin-bottom: 30px;
    }

    .accordion-hide {
        display: none;
    }

    #accordion-p {
        /*width: 900px;*/
        height: auto;
        margin-left: 25px;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #717883;
    }

    #accordion-capsule {
        display: flex;
        transform: scale(0.7);
    }

    .left-part {
        display: flex;
        flex-grow: 1;
        flex-direction: row;
        align-items: center;

    }

    .left-inside {
        display: flex;
        margin-left: 15px;
        flex-direction: column;
    }

    .right-part {
        display: flex;
        flex-grow: 1;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }

    .inlineBlock {
        display: flex;
        padding: 16px;
        flex-grow: 1;
        border-radius: 10px;
    }

    .accordion-content {
        padding: 0px 16px 16px;
    }

    #accordion-title {
        margin-left: 25px;
        font-size: 22px;
    }

    .capsule-container {
        margin-left: 40px;
        width: 136px;
        height: 43px;
        border-radius: 100px;
    }

    .bigCapsule {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 136px;
        height: 43px;
        border-radius: 100px;
        font-weight: 500;
        font-size: 18px;
    }

    .capsule-high {
        background: rgba(36, 168, 74, 0.05);
        border: 2px solid #24A84A;
        color: #24A84A;
    }

    .capsule-mid {
        background: rgba(252, 174, 23, 0.05);
        border: 2px solid #FCAE17;
        color: #FCAE17;
    }

    .capsule-low {
        background: rgba(238, 51, 35, 0.05);
        border: 2px solid #EE3323;
        color: #EE3323;
    }

    #accordion-score {
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        color: #717883;
    }

    #accordion-percentage {
        color: #192A46;
        font-size: 25px;
        font-weight: 500;
    }

    .bannerLeft {
        margin-left: 50px;
        margin-top: 50px;
    }

    .bannerRight {
        display: flex;
        margin-top: 70px;
        margin-right: 70px;
        flex-direction: row-reverse;
        align-items: flex-start;
    }

    #bannerTxt {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 19px;
        line-height: 30px;
        color: white;
    }

    #bannerPara {
        width: 300px;
        height: 48px;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: rgba(255, 255, 255, 0.5);
        margin-bottom: 47px;

    }

    #bannerBtn {
        padding: 10px 30px;
        cursor: pointer;
        outline: none;
        border: none;
        border-radius: 10px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 30px;
        color: #1A64D6;
    }

    #line2 {
        margin-top: 25px;
    }

    .radar-desc {
        display: flex;
        height: 50px;
        /*flex-direction: row-reverse;*/
        justify-content: center;
        align-items: center;
        border-radius: 10px;
    }

}