.shape {
    fill-opacity: 0.4;
}

.shape :hover {
    fill-opacity: 0.65;
    z-index: 100;
}

.radar-diagram {
    display: flex;
    justify-content: center;
    align-items: center;
}

.radar-diagram canvas {
    width: 800px !important;
    height: 800px !important;
}

.radar-label canvas {
    background: rgba(26, 100, 214, 0.1);
    border: 2px solid #1A64D6;
    border-radius: 100px;
}

.caption {
    position: absolute;
    padding: 40px;
    font-family: 'Poppins';
    font-style: normal;
    font-size: 18px;
    fill: #192A46;
    font-weight: 600;
    z-index: 100;
    overflow: visible;
    text-anchor: middle;
    text-overflow: ellipsis;
}

@media (max-width: 768px) {
    .radar-diagram {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .radar-diagram canvas {
        width: 300px !important;
        height: 300px !important;
    }
    .caption {
        background: rgba(26, 100, 214, 0.1) !important;
        border: 1px solid #1A64D6 !important;
        border-radius: 100px !important;
        position: absolute !important;
        padding: 40px !important;
        font-family: 'Poppins' !important;
        font-style: normal;
        font-size: 14px !important;
        fill: #192A46;
        font-weight: 600;
        z-index: 1000;
        overflow: visible;
        text-anchor: middle;
        text-overflow: ellipsis;
        width: 100% !important;
        /*filter:url(#solid);*/
    }
}

@media (min-width: 1024px) {
    .radar-diagram canvas {
        width: 800px !important;
        height: 800px !important;
    }
}