.mainContainer {
  display: flex;
  padding-top: 120px;
  margin-bottom: 0;
  align-items: center;
  flex-direction: column;
}
.mainContainer > h1 {
  width: 90%;
  font-weight: 600;
  color: #352958;
  font-size: 1.8rem;
  text-align: center;
}

.mainContainer > hr {
  width: 25%;
  border-width: 0;
  margin: 0.2rem 0;
  padding: 1.5px 0;
  border-radius: 100%;
  transform: skew(0deg);
  background-color: #8667de;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  width: 27vw;
  height: 25vw;
  margin: 0 3%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.card > img {
  width: 20vw;
}

@media screen and (min-width: 768px) {
  .cardContainer {
    width: 100%;
    padding: 0;
    align-items: center;
  }
  .mainContainer > h1 {
    width: 50%;
    font-size: 42px;
    line-height: 52px;
  }

  .card {
    width: 20%;
    margin: 0;
    padding: 2rem;
    height: fit-content;
  }

  .card > img {
    width: 8vw;
    object-fit: contain;
  }

  .mainContainer > hr {
    width: 10%;
    margin-bottom: 3rem;
  }
}
