.main_content_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    margin-top: 6rem;
    min-height: 70vh;
    align-items: center;
}

@media (max-width: 767px) {
    .main_content_container {
        min-height: 90vh;
        width: 100%;
    }
}