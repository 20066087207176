.mainContainer {
  display: flex;
  margin: 0 auto;
  padding: 2rem 0;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #f9f7fd;
}

.header {
  display: flex;
  justify-content: center;
}

.imgCertificate {
  z-index: 1;
  height: 67vw;
  object-fit: contain;
  border-radius: 0.3rem;
  /* border: 0.5rem solid #352958; */
}

.title {
  display: flex;
  font-weight: 600;
  font-size: 1.3rem;
  align-items: center;
  justify-content: center;
}
.titleContainer {
  margin: 0;
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
}

.logo {
  width: 8vw;
  height: 3vh;
  color: #8667df;
  font-size: 1.4rem;
  padding-top: 0.1rem;
  margin-right: 0.6rem;
}

.description {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.5rem;
}

.mainContainer > h1 {
  width: 90%;
  color: #352958;
  font-weight: 600;
  font-size: 1.7rem;
  text-align: center;
}

.mainContainer > hr {
  width: 25%;
  margin: 1rem 0;
  border-width: 0;
  padding: 1.5px 0;
  border-radius: 100%;
  transform: skew(0deg);
  background-color: #8667de;
}

.row {
  width: 75%;
  display: flex;
  margin: 2rem auto;
  align-items: center;
  flex-direction: column;
}

.bottom {
  z-index: 0;
  width: 6rem;
  left: -2.8rem;
  bottom: -3.6rem;
  position: absolute;
}

.top {
  z-index: 0;
  width: 6rem;
  right: -2.8rem;
  top: -3.6rem;
  position: absolute;
}

.imgContainer {
  margin: 2rem;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.mainContainer > h5 {
  width: 86%;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  margin: 0 auto;
  margin-top: 2rem;
  font-weight: 500;
  padding: 1rem 2rem;
  text-align: center;
  border-radius: 0.3rem;
  background-color: #5a2dda;
}
.mainContainer > p {
  margin-top: 1rem;
  color: #837b9a;
  font-style: italic;
  text-align: center;
}

.dots_top,
.dots_bottom {
  width: 12rem;
  z-index: 0;
  position: absolute;
}

.dots_top {
  top: 1rem;
  right: -1rem;
}
.dots_bottom {
  left: -2rem;
  bottom: 6rem;
}

@media screen and (min-width: 768px) {
  .imgContainer {
    width: fit-content;
    height: fit-content;
  }

  .imgCertificate {
    z-index: 1;
    width: 30rem;
    height: 22rem;
    border-width: 0;
    object-fit: contain;
    margin-right: 4.8rem;
    /* border: 0.5rem solid #352958; */
  }

  .ImageTextContainer {
    width: 83.5%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .mainContainer {
    width: 100%;
    padding: 96px 0;
  }

  .row > p {
    width: 60%;
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 1.2rem;
    text-align: start;
    width: 87%;
  }

  .logo {
    margin-right: 1rem;
    width: fit-content;
  }

  .IoMdCube_image{
    width: 20px;
     height: 20px;
  }
  .mainContainer > h1 {
    font-size: 48px;
    line-height: 74px;
  }
  .mainContainer > p {
    width: 70%;
    font-size: 1.1rem;
    line-height: 2rem;
  }
  .mainContainer > hr {
    width: 10%;
    padding: 1.5px 0;
  }
  .bottom {
    z-index: 0;
    left: -14%;
    width: 10rem;
    bottom: -5rem;
    position: absolute;
  }

  .top {
    z-index: 0;
    right: 20%;
    width: 10rem;
    top: -5rem;
    position: absolute;
  }
  .description {
    font-size: 16px !important;
  }
  .title {
    font-size: 22px;
  }
  .mainContainer > h5 {
    color: #fff;
    font-size: 32px;
    margin: 0 auto;
    margin-top: 5rem;
    font-weight: 500;
    padding: 1rem 2rem;
    width: max-content;
    text-align: center;
    border-radius: 0.5rem;
    background-color: #5a2dda;
  }
  .mainContainer > p {
    font-size: 24px;
    color: #837b9a;
    font-weight: 500;
    margin-top: 1.5rem;
  }
  .dots_top,
  .dots_bottom {
    width: 20%;
    z-index: 0;
    position: absolute;
  }

  .dots_top {
    top: 1rem;
    right: -1rem;
  }
  .dots_bottom {
    left: 3rem;
    bottom: 12rem;
  }
}

.container-md {
  max-width: calc(1400px + 40px);
  padding: 0 20px;
  margin: 0 auto;
}

.certificateAllContentAlignment {
  background: #F9F7FD;
  font-family: 'Poppins', sans-serif;
  padding: 120px 0px 50px 0;
}

.certificateAllContentAlignment2 {
  background: #FFF7F7;
  font-family: 'Poppins', sans-serif;
  padding: 120px 0px 50px 0;
}

.cerGrid {
  display: grid;
  grid-template-columns: 1fr 700px;
  gap: 145px;
  align-items: center;
}

.CertificateImageStyle img {
  max-width: 100%;
}
.sectionTitle {
  padding: 0 0 100px 0;
}
.sectionTitle2 {
  padding: 0 0 20px 0;
}
.sectionTitle h1 {
  font-weight: 700;
  font-size: 42px;
  letter-spacing: -0.01em;
  position: relative;
  line-height: 52px;
  margin: 0 auto;
  text-align: center;
  color: #352958;
}
.sectionTitle2 h1 {
  font-weight: 700;
  font-size: 42px;
  letter-spacing: -0.01em;
  position: relative;
  line-height: 52px;
  margin: 0 auto;
  text-align: center;
  color: #352958;
}

.sectionTitle h1::before {
  position: absolute;
  left: 50%;
  transform: translateY(-50%);
  bottom: -30px;
  width: 169px;
  height: 4px;
  content: "";
  background-image: url("../assets/images/text-vectore.svg");
}

.allTextIconContentAlignment .iconTextGrid:last-child {
  padding: 0;
}
.iconTextGrid {
  display: grid;
  grid-template-columns: 33px 1fr;
  padding: 0 0 30px 0;
  gap: 20px; 
}

.iconTextGridItems p{
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #352958;
  margin: 0 0 10px 0;

}

.iconTextGridItems span {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #A3A2B4;
}
@media screen and (max-width: 767px) {
  .cerGrid {
    grid-template-columns: repeat(1 , 1fr);
    gap: 0;
  }
  .sectionTitle h1 {
    font-weight: 700;
    font-size: 26px;
    line-height: 32px;
  }
  .sectionTitle2 h1 {
    font-weight: 700;
    font-size: 26px;
    line-height: 32px;
  }

  .sectionTitle h1::before {
    bottom: -10px;
    width: 80px;
    height: 2px;
    left: 37%;
  }
  .sectionTitle {
    padding: 0 0 60px 0;
  }
  .sectionTitle2 {
    padding: 0 0 10px 0;
  }
  .iconTextGrid .iconTextGridItems span {
    font-size: 14px;
    line-height: 24px;
  }
  .iconTextGrid .iconTextGridItems p {
    font-size: 18px;
    line-height: 26px;
  }
  .certificateAllContentAlignment {
    padding: 40px 0;
  }
  .certificateAllContentAlignment2 {
    padding: 40px 0;
  }
}