.mainContainer {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 5rem 0.5rem;
  flex-direction: column;
  justify-content: center;
}

.mainContainer > h1 {
  margin: 0;
  color: #352958;
  font-weight: 600;
  font-size: 1.6rem;
  text-align: center;
}
.mainContainer > hr {
  width: 20%;
  margin: 1rem 0;
  border-width: 0;
  padding: 1.5px 0;
  border-radius: 100%;
  transform: skew(0deg);
  background-color: #8667de;
}

.contentContainer {
  padding: 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.content {
  padding: 1.5rem;
  margin-bottom: 1rem;
  border-radius: 1rem;
  background-color: #f2effb;
}

.content > img {
  width: 100%;
  aspect-ratio: 16/9;
}

.content > h4 {
  font-weight: 600;
  color: #8667de;
}

.content > p,
.content > b {
  display: inline;
  font-size: 0.8rem;
}

/*  */

@media screen and (min-width: 768px) {
  .mainContainer > h1 {
    font-size: 42px;
    line-height: 52px;
  }

  .mainContainer > hr {
    width: 10%;
    margin: 2rem 0;
    padding: 1.5px 0;
  }

  .mainContainer {
    padding-bottom: 0;
    background-color: #ffff;
  }

  .contentContainer {
    padding: 2rem 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .content {
    width: 32%;
    margin: 0 1rem;
    padding: 1.8rem;
    align-self: stretch;
    margin-bottom: 1rem;
    border-radius: 1rem;
    background-color: #f2effb;
  }
  .content > h4 {
    font-size: 24px;
    margin: 20px 0;
  }

  .content > img {
    width: 100%;
    aspect-ratio: 16/9;
  }

  .content > h4 {
    color: #8667de;
  }

  .content > p,
  .content > b {
    display: inline;
    font-size: 18px;
    line-height: 30px;
  }
}

.applyButtonStyle{
  display: flex;
  justify-content: center;
  padding: 0 0 120px 0;
}
.applyButtonStyle button {
  padding: 16px 32px;
  border: none;
  letter-spacing: 0.02em;
  font-weight: 600;
  font-size: 20px;
  background: linear-gradient(135deg, #5330BA 0%, #8E2596 100%);
  box-shadow: 0px 10px 30px rgb(56 39 88 / 20%);
  border-radius: 15px;
  line-height: 30px;
  cursor: pointer;
  color: #F2EFFB;
}

@media screen and (max-width: 767px) {
  .applyButtonStyle {
    padding: 0;
  }

  
  .applyButtonStyle button {
    padding: 15px;width: 100%;
    font-weight: 700;
font-size: 14.6033px;
line-height: 22px;
  }
  .content > h4 {
    margin-top: 24px;
  }.content > p,
  .content > b {
    display: inline;
    font-size: 14px;
    line-height: 24px;
  } 
}