.bee-row,
.bee-row-content {
  position: relative;
}

body {
  background-color: #fff;
  color: #000;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
}

a {
  color: #0068a5;
}

* {
  box-sizing: border-box;
}

body,
h1,
h2,
h3 {
  margin: 0 ;
}

.bee-row-content {
  max-width: 1170px;
  margin: 0 auto;
  display: flex;
}

.bee-row-content .bee-col-w4 {
  flex-basis: 33%;
}

.bee-row-content .bee-col-w8 {
  flex-basis: 67%;
}

.bee-row-content .bee-col-w12 {
  flex-basis: 100%;
}

.bee-html-block {
  text-align: center;
}

.bee-icon .bee-icon-label-right a {
  text-decoration: none;
}

.bee-divider,
.bee-image {
  overflow: auto;
}

.bee-divider .center,
.bee-image .bee-center {
  margin: 0 auto;
}

.bee-row-1 .bee-col-1 .bee-block-1 {
  width: 100%;
}

.bee-icon,
.bee-icon .bee-icon-image,
.bee-icon .bee-icon-label-right {
  display: inline-block;
}

.bee-icon {
  vertical-align: middle;
}

.bee-icon .bee-icon-image {
  vertical-align: middle;
  margin-right: -4px;
}

.bee-image img {
  display: block;
  width: 100%;
}

@media (max-width: 768px) {
  .bee-row-content:not(.no_stack) {
    display: block;
  }
}

.bee-row-1,
.bee-row-2,
.bee-row-3,
.bee-row-4,
.bee-row-5 {
  background-repeat: no-repeat;
}

.bee-row-1 .bee-row-content,
.bee-row-5 .bee-row-content {
  background-repeat: no-repeat;
  color: #000;
}

.bee-row-1 .bee-col-1,
.bee-row-2 .bee-col-1,
.bee-row-3 .bee-col-1,
.bee-row-3 .bee-col-2,
.bee-row-4 .bee-col-1,
.bee-row-5 .bee-col-1 {
  padding-bottom: 5px;
  padding-top: 5px;
}

.bee-row-2 .bee-row-content,
.bee-row-3 .bee-row-content,
.bee-row-4 .bee-row-content {
  color: #000;
  background-repeat: no-repeat;
}

.bee-row-2 .bee-col-1 .bee-block-1 {
  width: 100%;
  text-align: center;
}

.bee-row-3 .bee-col-1 .bee-block-1,
.bee-row-3 .bee-col-2 .bee-block-1,
.bee-row-4 .bee-col-1 .bee-block-1 {
  padding: 10px;
}

.bee-row-5 .bee-col-1 .bee-block-1 {
  color: #9d9d9d;
  font-family: inherit;
  font-size: 15px;
  padding-bottom: 5px;
  padding-top: 5px;
  text-align: center;
}

.bee-row-5 .bee-col-1 .bee-block-1 .bee-icon-image {
  padding: 5px 6px 5px 5px;
}

.bee-row-5 .bee-col-1 .bee-block-1 .bee-icon {
  margin-left: 0;
  margin-right: 0;
}

.page-container {
  background: white;
  top: 75px;
  position: relative;
  width: 80%;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0px 0px 1px 1px #ddd;
  padding: 21px;
}

.student-info h3 {
  margin-top: 0px;
  margin-bottom: 5px;
  text-align: left;
  margin-left: 10px;
  margin-right: 10px;
}

.student-info table {
  width: calc(100% - 20px);
  margin: 0 10px;
  padding: 0;
  box-sizing: border-box;
  border: 1px solid #bbbbbb;
  border-radius: 8px;
  border-collapse: separate;
  box-shadow: 0px 0px 1px 1px #bbbbbb20;
}

.student-info table th,
.student-info table td {
  text-align: left;
  padding: 5px 10px;
}

.overall-info h3 {
  margin-top: 0px;
  margin-bottom: 5px;
}

.overall-info .tbl {
  width: calc(100% - 20px);
  margin: 0 10px;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-basis: 33.33%;
  height: 100px;
}

.overall-info .tbl .item {
  padding: 5px 10px;
  box-sizing: border-box;
  flex: 1;
  align-self: stretch;
  justify-self: stretch;
  margin-right: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.overall-info .tbl .item:last-child {
  margin-right: 0;
}

.overall-info .tbl .item h2 {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 22px;
  font-weight: 600;
}

.overall-info .tbl .item h4 {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 500;
}

.item.red {
  border: 2px solid #ccb1b1;
  background-color: #ffdcdc;
  box-shadow: 1px 1px 2px 2px #ffdcdc;
}

.item.blue {
  border: 2px solid #b1b1cc;
  background-color: #dcdcff;
  box-shadow: 1px 1px 2px 2px #dcdcff;
}

.item.green {
  border: 2px solid #b1ccb1;
  background-color: #dcffdc;
  box-shadow: 1px 1px 2px 2px #dcffdc;
}

.subject-info h3 {
  margin-top: 0px;
  margin-bottom: 5px;
}

.subject-info .tbl {
  width: calc(100% - 20px);
  margin: 0 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-basis: 33.33%;
  min-height: 95px;
}

.subject-info .tbl .item {
  padding: 5px 10px;
  box-sizing: border-box;
  flex: 1;
  align-self: stretch;
  justify-self: stretch;
  margin-right: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.subject-info .tbl .item:last-child {
  margin-right: 0;
}

.subject-info .tbl .item h2 {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 22px;
  font-weight: 600;
}

.subject-info .tbl .item h4 {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 500;
}

.item.red,
.item.green,
.item.blue,
.item.yellow {
  border: 1px solid #e0e0e0;
  background-color: #f9f9f9;
  box-shadow: 1px 1px 1px 1px #f0f0f0;
}

.item.list {
  top: 5px;
  position: relative;
}
.item.list ul {
  text-align: left;
  padding: 16px;
  margin: 0;
}

.item .blk {
  width: 100%;
  display: flex;
  flex-basis: 50%;
  align-items: center;
  justify-items: space-between;
}

.item .blk > .iitem {
  flex: 1;
  align-items: flex-start;
  align-self: center;
  justify-content: flex-start;
  text-align: left;
  padding: 5px 0;
}

.item .blk > .iitem.empty {
  flex: 0.15;
}

.item .blk > .progress {
  width: 100%;
  height: 16px;
  background: #fff;
  border-radius: 8px;
  justify-content: flex-start;
  text-align: center;
  position: relative;
  border: 1px solid #444;
}

.item .blk > .progress span {
  background: #444;
  display: inline-block;
  height: 10px;
  position: absolute;
  top: 2px;
  left: 3px;
  border-radius: 8px;
}
