.promo-modal-body {
  width: 84.5%;
  display: flex;
  max-height: 94vh;
  max-width: 400px;
  overflow-y: scroll;
  align-items: center;
  flex-direction: column;
  border-radius: 1rem;
  background-color: #fff;
  padding: 1.2rem 0 1.1rem 1.1rem;


}

.promo-modal {
  display: flex;
  width: fit-content;
  position: relative;
  justify-content: center;
}

.promo-image-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 0.5rem;
  margin-bottom: 0.5rem;
}
.promo-image-section > img {
  width: 2rem;
  margin-right: 1.6rem;
}

.promo-desc {
  gap: 1.6rem;
  width: 94%;
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;  
  scrollbar-width: none; 
}
.promo-carousel > img {
  width: 100%;
  scroll-snap-align: center;
}

.descriptionImageContainer {
  width: 8.5rem;
  height: 8.5rem;
  display: flex;
  margin-bottom: 1.6rem;
  align-items: center;
  border-radius: 0.5rem;
  justify-content: center;
  backdrop-filter: blur(5px);
  border: 2px solid rgba(255, 255, 255, 0.3);
  box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.3rem 0.5rem;
}

.descriptionImageContainer > img {
  width: 7.5rem;
  height: 7.5rem;
  object-fit: cover;
  border-radius: 0.4rem;
}

.promo-desc > p {
  width: 89vw;
  font-weight: 300;
  font-size: 1.2rem;
  line-height: 1.2rem;
}

.promo-desc > h6 {
  margin: 1.6rem 0 0 0;
}

.promo-details > h3 {
  width: 94%;
  font-size: 1.6rem;
  font-weight: 400;
}

.promo-offer-container {
  width: 94%;
  display: flex;
  justify-content: space-between;

}

.promo-offer-container > h5,
.promo-offer-container > p {
  margin: 0;
  font-weight: 500;
  font-size: 1.2rem;
  /* margin-bottom: 5rem; */
}
.promo-offer-container > p,
.promo-offer-container > a {
  color: #8667de;
  cursor: pointer;
  /* text-align: right;
  width: 100%; */
}

.promo-offer-container > h6,
.promo-offer-container > a {
  margin: 0;
  font-weight: 300;
  font-size: 0.6rem;
}

.promo-offer-container > a {
  font-weight: 400;
}

.promo-offer-container > p {
  text-shadow: rgba(0, 0, 0, 0.3) 0.2rem 0.2rem 0.3rem;
}

.summaryContainer {
  width: 94%;
  padding: 1.6rem;
  border-radius: 0.5rem;
  background-color: #f2f2f2;
  margin-top: 20px;
}

.summaryRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.summaryRow > p {
  font-size: 1.4rem;
  font-weight: 300;
}

.summaryRow > h5 {
  color: #8667de;
  margin: 0.3rem 0;
  font-weight: 300;
  font-size: 1.4rem;
}
.summaryRow > h3 {
  margin: 0.5rem 0 0 0;
  font-weight: 500;
  font-size: 1.8rem;
}

.price {
  min-width: 3.5rem;
}
.priceTotal {
  min-width: 3rem;
}

.payRow {
  width: 94%;
  display: flex;
  margin-top: 2rem;
  align-items: center;
  justify-content: space-between;
  margin-top:30px
}

.payRow > div > h5 {
  margin: 0;
}
.payRow > div > p {
  margin: 0.2rem 0;
  font-weight: 300;
  font-size: 1.1rem;
}

.payRow > h3 {
  width: 65%;
  color: #fff;
  cursor: pointer;
  font-weight: 400;
  font-size: 1.2rem;
  text-align: center;
  border-radius: 0.2rem;
  padding: 0.7rem 0.5rem;
  background-color: #8667de;
}

.promo-details > img {
  width: 95%;
  height: 3rem;
  margin-top: 2rem;
  object-fit: contain;
}

.promo-cross {
  top: 1px;
  right: 12%;
  position: absolute;
  z-index: 9999999;
}

.selectOfferContainer {
  width: 94%;
  display: flex;
  align-items: center;
}
.selectOfferContainer > div {
  flex: 2;
  margin-left: 0.3rem;
}
.selectOfferContainer > div > h6 {
  margin: 0;
  font-weight: 400;
  font-size: 0.9rem;
}

.selectOfferContainer > div > p {
  font-weight: 300;
  color: #828282;
  margin: 0.5rem 0;
  font-size: 0.65rem;
}

.selectOfferContainer > a {
  margin: 0;
  height: 100%;
  color: #8667de;
  font-weight: 500;
  font-size: 0.7rem;
}

.selectOfferContainer > img {
  margin-bottom: 2%;
}
.promo-image-section-2 {
  display: none;
}
.promo-desc {
  position: relative;
}
.carouserDots {
  left: 37vw;
  bottom: 5px;
  display: flex;
  position: absolute;
}
.carouselDot {
  font-size: 0.5rem;
  margin: 0 0.1rem;
}

.promo-arrow-container {
  top: 50%;
  width: 100%;
  display: flex;
  font-size: 1.6rem;
  position: absolute;
  justify-content: space-between;
}
.promoLabel {
  color: #8667de;
  font-size: 2vh;
}
.promoInput {
  outline: none;
  padding: 0.3rem;
  border-width: 1px;
  border-radius: 0.3rem;
}
.productSubLabel {
  font-size: 1.2rem;
  color: #222;
}
.promocode {
  width: 92%;
  display: flex;
  align-items: center;
  height:60px;
}
.promocode > div {
  width: 100%;
}
.promocode > img {
  width: 3.5rem;
  height: 3.5rem;
  margin-right: 2rem;
  object-fit: contain;
}

.promo-offer-container {
  width: 100%;

}

.promo-offer-container h6{
  margin-top:5px;
  margin-bottom:5px;
}

.promo-offer-container a{
  margin-top:5px;
  margin-bottom:5px;
}


promo-arrow-icon {
  height: 4rem;
  padding: 1.6rem 0;
}

.promo-header-image {
  width: 10%;
}

@media screen and (min-width: 768px) {
  .promo-modal {
    width: 100%;
  }

  .promo-modal-body {
    display: flex;
    min-height: 70vh;
    max-width: 75%;
    overflow-y: scroll;
    flex-direction: row;
    padding: 0.5rem 0 1.6rem 0.5rem;
    justify-content: space-between;
    -ms-overflow-style: none;  
    scrollbar-width: none;
  }
  .promo-image-section {
    display: none;
  }
  .promo-image-details {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
  }
  .promo-desc {
    width: 49%;
    padding: 2rem 1.6rem 3rem;
  }
  .promo-carousel > img {
    height: 100%;
    object-fit: contain;
  }
  .promo-carousel {
    height: 100%;
    margin: 0 auto;
    position: relative;
    width: fit-content;
  }
  .promo-details {
    width: 50%;
    padding: 2rem 0rem 1.6rem 2rem;

  }

  .promo-image-section-2 {
    width: 96%;
    display: flex;
    align-items: center;
    padding-left: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .discovery > p {
    font-size: 1.6rem;
    font-weight: 500;
  }
  .discovery > h4 {
    margin: 0;
    font-size: 1.5rem;
  }

  .promo-image-section-2 > img {
    width: 2rem;
    margin: 2rem 1.6rem 2rem 0;
  }
  .discovery {
    display: flex;
    flex-direction: column;
  }

  .selectOfferContainer > div > h6 {
    text-align: start;
    width: 100%;
  }

  .promo-details > img {
    width: 100%;
    height: 8rem;
    margin: 0 auto;
    margin-top: 5.6rem;
    object-fit: contain;
  }

  .payRow > h3 {
    width: 40%;
    font-size: 1.6rem;
    text-align: center;
    padding: 1.1rem 2rem;
  }
  .payRow > div > h5 {
    font-size: 1.6rem;
  }
  .payRow > div > p {
    font-size: 1.6rem;
  }

  .promo-cross {
    top: 8%;
    right: 20%;
  }

  .promo-details > h3 {
    font-size: 1.9rem;
    margin-top: 0rem;
    margin-bottom: 1rem;
  }

  .promo-offer-container > a,
  .promo-offer-container > h6 {
    font-size: 1.6rem;
  }
  .promo-cross {
    right: 14vw;
    font-size: 2rem;
  }
  .carouserDots {
    left: 46%;
    bottom: 1vw;
    display: flex;
    position: absolute;
  }
  .promo-arrow-container {
    top: 50%;
    font-size: 2rem;
  }
}



/*  Offer Model CSS */

.offerContainer {
  width: 100%;
  height: 100vh;
  overflow: scroll;
  max-width: 400px;
  background-color: #fff;
  padding: 1.2rem 0 1.1rem 1.1rem;

}



.detail>header {
  padding: 1rem;
  display: flex;
  align-items: center;
  height:80px;
  margin-top:20px
}

.detail>header>p {
  font-size: 2rem;
  margin-left: 1rem;
  margin-bottom: 0rem;
}

.detail>section {
  display: flex;
  margin-top: 1rem;
  align-items: center;
  padding: 0.8rem 1.5rem;
  background-color: #e7f1f7;
}

.offerContainer>section>h3 {
  margin: 0 0.5rem;
  font-weight: 600;
  color: #333333;
}

.promoCodes {
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
 
}

.promoCodes>article {
  margin: 1rem 0;
  display: flex;
  padding: 2rem 1rem;
  align-items: center;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.3) 0rem 0rem 0.3rem;
  border-radius: 0.5rem;
}

.promoCodes>article>h4 {
  width: 90%;
  margin: 0;
  font-weight: 500;
  font-size:2rem;
}

.promoCodes>article>p {
  width: 90%;
  display: flex;
  font-weight: 500;
  margin: 0.5rem 0;
  color: #8667de;
  font-size:1.3rem;
}

.promoCodes>article>p>img {
  margin-right: 0.4rem;
}

.promoCodes>article>h6 {
  width: 90%;
  font-weight: 300;
  margin: 0.5rem 0;

}

.promoCodes>article>hr {
  height: 1px;
  width: 100%;
  margin: 0.5rem 0;
  border-width: 0;
  background-color: #f9f9f9;

}

.promoCodes>article>div {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.promoCodes>article>div>h6 {
  margin: 0;
  color: #8667de;
  font-weight: 400;
  font-size:1.4rem;
  width:60%;
}

.promoCodes>article>div>h4 {
  margin: 0;
  width: 35%;
  color: #fff;
  font-weight: 600;
  font-size: 1.2rem;
  text-align: center;
  padding: 0.4rem 1rem;
  border-radius: 0.2rem;
  background-color: #8667de;
  height:35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.descriptionContainer {
  display: none;
}

.descriptionContainer {
  position: relative;
}

.carouserDots {
  left: 32vw;
  bottom: 5px;
  display: flex;
  position: absolute;
}

.carouselDot {
  font-size: 0.5rem;
  margin: 0 0.1rem;
}

.arrowContainer {
  top: 50%;
  width: 100%;
  display: flex;
  font-size: 1rem;
  position: absolute;
  justify-content: space-between;
}

.arrow {
  height: 4rem;
  padding: 1rem 0;
}

.detail>header {
  position: sticky;
  top: -1rem;
  background-color: #fff;
}

.detail>section {
  position: sticky;
  top: 3rem;
}

.promoInput {
  padding: 0.5rem;
  margin-top: 1rem;
  margin-left: 1rem;
  border-radius: 0.5rem;
  outline-color: #8667de;
  border: 2px solid #8667de;
}

.customPromo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.customPromo>input {
  width: 60%;
  height: 40px;
  margin-top:20px;
  font-size:1.5rem;
  padding-left: 1rem;
}

.customPromo>h4 {
  width: 25%;
  margin: 1.4rem 1rem 0 1rem;
  color: #fff;
  font-weight: 600;
  font-size: 1.2rem;
  text-align: center;
  padding: 0.4rem 1rem;
  border-radius: 0.2rem;
  background-color: #8667de;
  height:40px;
  display: flex;
  justify-content:center;
  align-items: center;
}

.error {
  color: red;
  margin: 1rem;
  font-size: 1rem;
}

@media screen and (min-width: 768px) {
  .offerContainer {
    display: flex;
    max-width: 75%;
    max-height: 70vh;
    min-height: 50vh;
    overflow-y: scroll;
    flex-direction: row;
    padding: 0.5rem 0 1rem 0.5rem;
    justify-content: space-between;
    -ms-overflow-style: none;  
    scrollbar-width: none; 
  }

  .promo-modal-body ::-webkit-scrollbar {
    display: none;
  }

  .carouselImageContainer {
    position: relative;
    height: 100%;
    width: fit-content;
    margin: 0 auto;
  }

  .detail {
    width: 50%;
    height: 100%;
    padding: 1rem 0rem 1rem 2rem;
  }

  .descriptionContainer {
    width: 46%;
    display: flex;
    aspect-ratio: 1/1;
    padding: 2rem 1rem 3rem;
  }

  .carouselImageContainer>img {
    height: 100%;
    object-fit: contain;
  }

  .promoCodes>article>div>h4 {
    width: 25%;
  }

  .carouserDots {
    left: 46%;
    bottom: 1vw;
    display: flex;
    position: absolute;
  }

  .icon {
    right: 13vw;
    font-size: 2rem;
  }

  .carouserDots {
    left: 46%;
    bottom: 1vw;
    display: flex;
    position: absolute;
  }

  .arrowContainer {
    top: 50%;
    /* padding: 0.5rem; */
    font-size: 3rem;
  }
}