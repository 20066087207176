
.newFaqContainer {
    width: 100%;
    /*padding: 0 20px;*/
    margin: 0 auto;
}

.faqContentAlignment {
    display: grid;
    grid-template-columns: repeat(2 , 1fr);
    gap: 50px;
}

.faqContainer {
    display: flex;
    padding: 1rem;
    align-items: center;
    flex-direction: column;
}

.faqContainer > p {
    margin: 0;
    color: #352958;
    font-weight: 600;
    font-size: 1.8rem;
    text-align: center;
}

.faqContainer > hr {
    width: 25%;
    border-width: 0;
    padding: 1.5px 0;
    border-radius: 100%;
    transform: skew(0deg);
    margin: 1rem 0 2rem 0;
    background-color: #8B292A;
    opacity: 1;
}

.faqCardOpen,
.faqCard {
    width: 100%;
    margin: 0 0 25px 0 !important;
    cursor: pointer !important;
    border-radius: 1rem;
    padding: 0.8rem 1rem 1rem 1rem;
}

.faqCard {
    height: 8rem;
    border: 1px solid #D9D9D9;
    display: flex;
    align-items: center;
}

.faqCardOpen {
    margin: 1rem 0;
    background: #FAE6E6;
    border: 1px solid #8B292A;
}

.question {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.question p {
    margin: 0;
}
.question > p > span {
    color: #8B292A;
    font-weight: 500;
    font-size: 1.1rem;
    margin-right: 0.6rem;
}

.question > h5 {
    margin: 0;
    width: 100%;
    color: #352858;
    font-weight: 600;
    letter-spacing: 0.01em;
    font-size: 20px;
    line-height: 26px;
}

.answer {
    display: flex;
    margin-top: 0.3rem;
}

.answer > hr {
    margin: 0;
    padding: 0.05rem;
    height: auto;
    border-width: 0;
    margin: 0 1.6rem 0 0.7rem;
    background-color: #8667de;
}
.answer > p {
    color: #7e788e;
    font-size: 0.8rem;
    line-height: 1.2rem;
}

.iconUp {
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
    color: #8667de;
    height: fit-content;
}
.iconDown {
    width: 2rem !important;
    height: 2rem !important;
    padding: 0.5rem;
    color: #c2bfcd;
    height: fit-content;
    border-radius: 50rem;
    background-color: white;
    box-shadow: #e0ede8 0.5rem 0.6rem 2rem;
}

.faqCardContainer > h5 {
    width: 100%;
    color: #8667de;
    font-weight: 600;
    font-size: 0.9rem;
    text-align: center;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    border: solid 0.15rem #ede8fa;
}

@media screen and (min-width: 768px) {
    .faqContainer {
        padding: 0 0 5rem 0;
    }
    .faqCardContainer {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        /*padding-left: 3%;*/
        flex-direction: column;
    }

    .faqCardContainer > h5 {
        display: none;
    }
    .faqCard {
        height: 7rem;
        border: 1px solid #D9D9D9;
        display: flex;
        align-items: center;
    }

    .faqCardOpen,
    .faqCard {
        width: 98%;
        margin: 0;
    }
    .faqContainer > hr {
        width: 10%;
        margin: 2rem 0;
    }
    .faqContainer > p {
        font-size: 42px;
        line-height: 74px;
    }

    .question > p > span {
        font-size: 20px;
    }

    .question > h5 {
        font-weight: 600;
        font-size: 18px;
    }
    .answer > p {
        width: 90%;
        font-size: 15px;
        margin-top: 1rem;
        line-height: 22px;
    }
    .answer > hr {
        margin-top: 1.4rem;
    }

    .iconUp,
    .iconDown {
        padding: 0.4rem;
    }
}


@media screen and (max-width: 767px)  {
    .faqContentAlignment {
        grid-template-columns: repeat(1 , 1fr);
    }

    .question > h5 {
        font-size: 12px;
        line-height: 22px;
    }

    .answer > p  {
        font-size: 11px;
        line-height: 20px;
    }

    .faqCardOpen {
        margin: 1rem 0;
        background: #FAE6E6;
        border: 1px solid #8B292A;
        box-shadow: none;
    }


}